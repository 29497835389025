import {
  GetUnlicensedAggregatorDocument,
  LicensedWorkgroupOnlyPrivateSlugsAndNamesDocument,
  Maybe,
  Scalars,
  WorkgroupBySlugDocument,
} from '@/types-aggregatore';
import client from '@/utils/apolloClient';

export const convertStringToSlug = (value?: string) => (value ? value.replace(/ /g, '-') : '');

type WorkgroupsSlugsAndNamesResult = {
  inclinicSlug?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export const defaultServicesForPrenotaVisita = [
  {
    slug: 'ecografia-addome-completo',
    title: 'Ecografia Addome Completo',
  },
  {
    slug: 'radiografia-torace-rx',
    title: 'Radiografia Torace (RX)',
  },
  {
    slug: 'visita-dermatologica',
    title: 'Visita Dermatologica',
  },
  {
    slug: 'visita-ginecologica',
    title: 'Visita Ginecologica',
  },
  {
    slug: 'visita-cardiologica',
    title: 'Visita Cardiologica',
  },
  {
    slug: 'visita-oculistica',
    title: 'Visita Oculistica',
  },
  {
    slug: 'visita-neurologica',
    title: 'Visita Neurologica',
  },
  {
    slug: 'visita-odontoiatrica',
    title: 'Visita Odontoiatrica',
  },
  {
    slug: 'visita-urologica',
    title: 'Visita Urologica',
  },
];

export const convertSlugToString = (value?: string) => (value ? value.replace(/-/g, ' ') : '');
import { unstable_cache } from 'next/cache';

export const getWorkgroupBySlug = unstable_cache(
  async (slug: string) => {
    console.log(`Obtaining workgroup with slug ${slug}`);
    const wk = await client.query({
      query: WorkgroupBySlugDocument,
      variables: { slug },
      fetchPolicy: 'no-cache',
    });
    return wk;
  },
  ['getWorkgroupBySlug'],
);

export const staticLicensedAmbulatories = async (): Promise<WorkgroupsSlugsAndNamesResult[]> => {
  try {
    const result = await client.query({
      query: LicensedWorkgroupOnlyPrivateSlugsAndNamesDocument,
      variables: { limit: 100000, offset: 0 },
    });

    return result.data.licensedWorkgroupOnlyPrivateSlugsAndNames?.results ?? [];
  } catch (error) {
    throw new Error((error as unknown as Error)?.message);
  }
};

export const staticUnlicensedAmbulatories = async (): Promise<WorkgroupsSlugsAndNamesResult[]> => {
  try {
    const result = await client.query({
      query: GetUnlicensedAggregatorDocument,
    });

    return result.data.unlicensedWorkgroups ?? [];
  } catch (error) {
    throw new Error((error as unknown as Error)?.message);
  }
};
