import { StoryblokReactComponent } from '@/app/prenota-visita/_storyblok/componentsMap';
import { VisitMainInformationCardStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';

export const VisitMainInformationCard: StoryblokReactComponent<VisitMainInformationCardStoryblok> = ({ component: card }) => {
  return (
    <li className="mx-auto flex max-w-xs flex-grow flex-row items-center justify-center gap-4">
      {card.icon && (
        <Image className="size-12 flex-shrink-0 object-contain" src={card.icon.filename} alt={card.icon.alt ?? ''} height={96} width={96} />
      )}
      <div className="flex flex-col">
        <h2 className="text-xl font-medium">{card.title}</h2>
        <p className="text-sm text-neutral-lighter">{card.subtitle}</p>
      </div>
    </li>
  );
};
