import { ServiceCardStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { StoryblokReactComponent } from '@/app/prenota-visita/_storyblok/componentsMap';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
export const StoryblokServiceCard: StoryblokReactComponent<ServiceCardStoryblok> = ({ component: card }) => {
  const urlParams = new URLSearchParams();
  if (card.location) {
    urlParams.set('location', card.location.toLowerCase());
  }
  urlParams.set('service', card.service.toLowerCase());

  return (
    <li key={card._uid} className="group w-full flex-grow">
      <Link
        href={card.link ? resolveWebLink(card.link) : `/cerca?${urlParams.toString()}`}
        className="shadow-1 flex flex-row items-center rounded-xl border border-white bg-white px-5 py-3 transition-colors group-hover:border-elty-green group-focus:border-elty-green"
      >
        <div className="flex flex-grow flex-col gap-2">
          <div className="flex flex-row items-center gap-3">
            <Image
              src={`${DAVINCI_CDN}/inclinic/icons/medical-services.png`}
              alt="icona servizio medico"
              width={24}
              height={24}
              aria-hidden
            />
            <span aria-label="prestazione">{card.service}</span>
          </div>
          {card.location && (
            <div className="text-text-body flex flex-row items-center gap-3 text-sm text-neutral-lighter">
              <Image src={`${DAVINCI_CDN}/inclinic/icons/pin.png`} width={24} height={24} alt="Right arrow" />
              <span aria-label="posizione">{card.location}</span>
            </div>
          )}
          {card.description && (
            <div className="flex flex-row items-center gap-3 py-3 pr-3 text-sm text-neutral-lighter">
              <span aria-label="posizione">{card.description}</span>
            </div>
          )}
        </div>
        <ChevronRightIcon
          className="shrink-0 translate-x-0 transition-transform group-hover:translate-x-2"
          title="icona freccia destra"
          width={20}
          height={20}
          color="#07142F"
          aria-hidden
        />
      </Link>
    </li>
  );
};
