import { PostStoryblok, RelatedArticlesStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { getAllCmsStory } from '@/storyblok/utils/storyblok';
import Link from 'next/link';

export const StoryblokRelatedArticles: StoryblokReactComponent<RelatedArticlesStoryblok> = async ({ component, story }) => {
  const [categoryId] = component.category;

  const postsFilterQuery = {
    component: { in: 'post' },
    ...(categoryId ? { categories: { any_in_array: categoryId } } : {}),
  };

  const result = await getAllCmsStory<PostStoryblok>({
    starts_with: 'guide/',
    page: 1,
    per_page: 3,
    sort_by: 'published_at:desc',
    is_startpage: false,
    resolve_relations: ['post.categories'],
    filter_query: postsFilterQuery,
  });

  if (!result) return null;

  const {
    data: { stories: latestPosts },
  } = result;

  return (
    <div className="container mx-auto flex flex-col items-start justify-center gap-16 py-12 md:py-24">
      <h2 className="w-full text-center text-3xl text-[#031323]">{component.title}</h2>
      <div className="flex w-full flex-col md:flex-row">
        {latestPosts.map((post, i) => {
          return (
            <>
              <Article index={i} key={post.id} title={post.content.title} url={`/guide/${post.slug}`} />
              {i !== latestPosts.length - 1 && (
                <hr className="mx-0 my-6 flex h-[2px] w-full bg-secondary md:mx-6 md:my-0 md:h-auto md:w-[2px]" />
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export const Article: React.FC<{ title: string; index: number; url: string }> = ({ title, index, url }) => {
  return (
    <div className="flex flex-1 gap-4">
      <span className="w-16 flex-shrink-0 text-4xl font-semibold text-secondary">{`${index + 1}`.padStart(2, '0')}</span>
      <div className="flex flex-col gap-6">
        <h2 className="font-semibold text-neutral-darker">{title}</h2>
        <Link className="mt-auto text-secondary underline" href={url}>
          Leggi {'>'}
        </Link>
      </div>
    </div>
  );
};
