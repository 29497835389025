import { Lora } from 'next/font/google';
import { StoryblokReactComponent } from './componentsMap';
import { HowWorkTextStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';

import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx/lite';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokHowWorkText: StoryblokReactComponent<HowWorkTextStoryblok> = ({ component }) => {
  return (
    <section className={'px-4 py-12 lg:px-20 lg:pb-24 lg:pt-12'}>
      <div className="flex w-full flex-col items-center gap-7 rounded-2xl bg-white p-7 shadow-bigCard md:px-20 lg:flex-row lg:gap-24 lg:py-20">
        {component.image?.filename && (
          <Image
            src={component.image.filename}
            alt={component.image.alt ?? ''}
            aria-hidden="true"
            className="w-[200px] flex-shrink-0 lg:hidden"
            height={200}
            width={200}
          />
        )}
        <div className="flex flex-col gap-7">
          <h2 className="text-3xl">
            {render(component.title, {
              markResolvers: {
                [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
              },
            })}
          </h2>
          <div className="text-start [&>p]:mb-2">
            {render(component.description, {
              markResolvers: {
                [MARK_BOLD]: child => <span className={'font-semibold'}>{child}</span>,
              },
            })}
          </div>
        </div>
        {component.desktopImage?.filename && (
          <Image
            src={component.desktopImage.filename}
            alt={component.desktopImage.alt ?? ''}
            aria-hidden="true"
            className="hidden w-[300px] flex-shrink-0 lg:block"
            height={586}
            width={400}
          />
        )}
      </div>
    </section>
  );
};
