'use client';
import { Lora } from 'next/font/google';
import { StoryblokReactComponentProps } from './componentsMap';
import { VisitMainInformationStoryblok } from '@/types/types-storyblok';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx/lite';
import { useState } from 'react';
import { ELButton } from '@davincihealthcare/elty-design-system-react';
import { StoryblokComponent } from '@/app/prenota-visita/_storyblok/storyblokComponent';
import { replaceAdditionalInfoInRichText } from '@/app/prenota-visita/_storyblok/utils';
const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

// eslint-disable-next-line no-restricted-syntax
export function VisitMainInformation({ component, story, additionalData }: StoryblokReactComponentProps<VisitMainInformationStoryblok>) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <section className={'shadow-1 mx-auto flex flex-col items-center gap-16 bg-primary-light-active px-4 pb-14 pt-20 md:px-20 lg:py-20'}>
      <div className="display mx-auto flex max-w-[700px] flex-col">
        <h1 className="mb-7 flex flex-col items-center gap-4 text-center text-3xl text-neutral-darker">
          {render(component.title, {
            markResolvers: {
              [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
            },
            textResolver: text =>
              replaceAdditionalInfoInRichText(text, { locationName: additionalData.locationName, service: additionalData.service }),
          })}
        </h1>
        <div className={`mb-4 text-ellipsis text-center text-sm text-neutral-darker [&>p]:mb-2 ${!isExpanded ? 'line-clamp-2' : ''}`}>
          {render(component.subtitle, {
            markResolvers: {
              [MARK_BOLD]: child => <span className={'font-semibold'}>{child}</span>,
            },
            textResolver: text =>
              replaceAdditionalInfoInRichText(text, { locationName: additionalData.locationName, service: additionalData.service }),
          })}
        </div>

        <ELButton
          label={isExpanded ? 'Mostra meno' : 'Leggi di più'}
          color="primary"
          variant="text"
          size="small"
          aria-label={isExpanded ? 'Mostra meno' : 'Leggi di più'}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
      <ul className={clsx('mx-auto flex max-w-screen-xl flex-col gap-4 md:grid md:grid-cols-3 md:gap-8')}>
        {(component.cards ?? []).map(card => (
          <StoryblokComponent story={story} key={card._uid} component={card} additionalData={additionalData} />
        ))}
      </ul>
    </section>
  );
}
