'use client';

import { HomeHeroBannerSearchFormStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponentProps } from '@/app/prenota-visita/_storyblok/componentsMap';
import { storyblokEditable } from '@storyblok/js';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';
const SearchBar = dynamic(() => import('@/app/_components/searchbar'), { ssr: false });
// eslint-disable-next-line no-restricted-syntax
export function StoryblokHomeHeroBannerSearchForm({
  component,
  additionalData,
}: StoryblokReactComponentProps<HomeHeroBannerSearchFormStoryblok>) {
  const isHomePage = additionalData.pageKey === 'pv-home';

  return (
    <div className="relative z-[3] xl:flex xl:flex-row" {...storyblokEditable(component)}>
      <div className="shadow-1 relative flex flex-col gap-6 rounded-xl border border-black/5 bg-white p-6">
        <div className="flex flex-row gap-4">
          <h2 className="font-medium md:text-lg">{component.title}</h2>
        </div>
        <p>{component.subtitle}</p>
        <div className="hidden md:block">
          <Suspense>
            <SearchBar id="searchBarDesktop" inline defaultService={isHomePage ? null : additionalData.serviceName} />
          </Suspense>
        </div>
        <div className="block md:hidden">
          <Suspense>
            <SearchBar id="searchBarMobile" defaultService={isHomePage ? null : additionalData.serviceName} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
