import { convertSlugToString } from '@scripts/staticBuildUtils';

export const replaceAdditionalInfoInRichText = (text: string, additionalInfo: { service?: string; locationName?: string }) => {
  let newText = text;
  if (additionalInfo.service) {
    newText = newText.replaceAll('[[service]]', convertSlugToString(additionalInfo.service));
  }
  if (additionalInfo.locationName) {
    newText = newText.replaceAll('[[city]]', additionalInfo.locationName);
  }
  return newText;
};
