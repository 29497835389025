import { AlgoliaClinicV2 } from '@/types/algolia-types';
import { ItemList, WithContext } from 'schema-dts';

type CarouselWithContext = WithContext<ItemList>;

export const jsonLdMedicalClinicCarouselList = (clinics: AlgoliaClinicV2[]): CarouselWithContext => {
  if (!clinics?.length) return { '@context': 'https://schema.org', '@type': 'ItemList' };

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: clinics.map((clinic, index) => {
      return {
        '@type': 'ListItem',
        position: `${index}`,
        url: `https://elty.it/centri-medici/${clinic?.slug}`,
      };
    }),
  };
};
