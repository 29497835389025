import { StoryblokComponent } from '@/types/types-storyblok';
import { FunctionComponent } from 'react';
import { StoryblokPageComponent } from './_page';
import { StoryblokSmallCardsSection } from './smallCardsSection';
import { StoryblokServiceCard } from './smallCardsSection/serviceCard';
import { StoryblokSmallCardWithImage } from './smallCardsSection/smallCard';
import { type StoryblokStory } from 'storyblok-generate-ts';
import { VisitMainInformation } from '@/app/prenota-visita/_storyblok/visitMainInformation';
import { VisitMainInformationCard } from '@/app/prenota-visita/_storyblok/visitMainInformationCard';
import { StoryblokHomeHeroBanner } from '@/app/prenota-visita/_storyblok/homeHeroBanner';
import { StoryblokHomeHeroBannerSearchForm } from '@/app/prenota-visita/_storyblok/searchForm';
import { StoryblokHowWorkText } from '@/app/prenota-visita/_storyblok/howWorkText';
import { StoryblokMedicalVisitList } from '@/app/prenota-visita/_storyblok/medicalVisitList';
import { StoryblokRelatedArticles } from '@/app/prenota-visita/_storyblok/relatedArticles';
import { StoryblokHowWorkCards } from '@/app/prenota-visita/_storyblok/howWorkCards';
import { StoryblokHowWorkCardsCard } from '@/app/prenota-visita/_storyblok/howWorkCardsCard';
import { StoryblokMedicalVisitCard } from '@/app/prenota-visita/_storyblok/medicalVisitCard';
import { StoryblokBookingCardListing } from '@/app/prenota-visita/_storyblok/bookingCardListing';
import { StoryblokPrenotaPageComponent } from './_prenotaPage';
import { StoryblokFaqList } from '@/app/prenota-visita/_storyblok/faq/FaqList';
import { StoryblokFaqCard } from '@/app/prenota-visita/_storyblok/faq/FaqCard';

export type StoryblokReactComponent<T extends StoryblokComponent> = FunctionComponent<StoryblokReactComponentProps<T>>;
type storyblokPages = 'pv-home' | 'pv-service' | 'pv-service-location' | 'sp-service-location' | 'sp-service' | 'sp-home';
export type StoryblokReactComponentProps<T extends StoryblokComponent> = {
  component: T;
  story: StoryblokStory<StoryblokComponent>;
  additionalData: {
    pageKey: storyblokPages;
    service?: string;
    serviceName?: string;
    location?: string;
    locationName?: string;
    locationInfo?: {
      latitude: number;
      longitude: number;
    };
    searchParams?: { page: string; exclude: string; filterService: string; location: string };
    topServicesAtLocation?: { slug: string; title: string }[];
  };
};

export const componentsMap: {
  [SBComponent in StoryblokComponent as SBComponent['component']]: StoryblokReactComponent<SBComponent>;
} = {
  bookingCardListing: StoryblokBookingCardListing,
  faqCard: StoryblokFaqCard,
  faqList: StoryblokFaqList,
  howWorkCards: StoryblokHowWorkCards,
  howWorkCardsCard: StoryblokHowWorkCardsCard,
  howWorkText: StoryblokHowWorkText,
  medicalVisitCard: StoryblokMedicalVisitCard,
  medicalVisitList: StoryblokMedicalVisitList,
  page: StoryblokPageComponent,
  pagePrenotaVisita: StoryblokPrenotaPageComponent,
  relatedArticles: StoryblokRelatedArticles,
  serviceCard: StoryblokServiceCard,
  smallCardsSection: StoryblokSmallCardsSection,
  smallCardWithImage: StoryblokSmallCardWithImage,
  visitMainInformationCard: VisitMainInformationCard,
  adBanner: () => null,
  appStoreButton: () => null,
  author: () => null,
  blogHomepage: () => null,
  bookingCta: () => null,
  bookingCtaSmall: () => null,
  bulletPoint: () => null,
  cardsCarousel: () => null,
  cardsSectionCard: () => null,
  cardsWithBackgroundCarousel: () => null,
  cardsWithBackgroundCarouselCard: () => null,
  category: () => null,
  centeredHeroBanner: () => null,
  compareTable: () => null,
  contentSectionCarousel: () => null,
  CookiebotCookiesDeclaration: () => null,
  ctaBanner: () => null,
  dynamicText: () => null,
  emptyHeader: () => null,
  feature: () => null,
  Footer: () => null,
  footerButton: () => null,
  grid: () => null,
  Header: () => null,
  homeHeroBanner: StoryblokHomeHeroBanner,
  homeHeroBannerDavinciInfoForm: () => null,
  homeHeroBannerRichtextWithCta: () => null,
  homeHeroBannerSearchForm: StoryblokHomeHeroBannerSearchForm,
  homeHeroBannerSelect: () => null,
  htmlEmbed: () => null,
  hubspotForm: () => null,
  image: () => null,
  imageWithLink: () => null,
  inlineCallToAction: () => null,
  Link: () => null,
  linkCallToAction: () => null,
  LinkGroup: () => null,
  logosStrip: () => null,
  logosStripBig: () => null,
  logosStripBigRow: () => null,
  logoStripBigRowItem: () => null,
  newsletterBanner: () => null,
  peopleCarousel: () => null,
  peopleCarouselCard: () => null,
  plansCarousel: () => null,
  plansCarouselPlan: () => null,
  post: () => null,
  postsBanner: () => null,
  postsConfiguration: () => null,
  prenotaVisita: () => null,
  prenotaVisitaCity: () => null,
  priceCard: () => null,
  priceCardBulletPoint: () => null,
  prices: () => null,
  pricesCompareTable: () => null,
  pricesTab: () => null,
  reviewsCarousel: () => null,
  richtextTitle: () => null,
  scriptEmbed: () => null,
  section: () => null,
  sectionWithBulletpointsAndImage: () => null,
  seoData: () => null,
  smallCardsCarousel: () => null,
  smallCardsCarouselItem: () => null,
  Social: () => null,
  standardCardsCarousel: () => null,
  teaser: () => null,
  testimonial: () => null,
  testimonialStrip: () => null,
  verticalSpace: () => null,
  visitMainInformation: VisitMainInformation,
};
