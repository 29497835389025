export const makeJsonLdSchema = () => {
  const questionsNodes = document.getElementsByClassName('faq-question');
  const answersNodes = document.getElementsByClassName('faq-answer');
  const mainEntity = [];
  for (let i = 0; i < questionsNodes?.length && i < answersNodes?.length; i++) {
    const questionNode = questionsNodes[i];
    const answerNode = answersNodes[i];
    const question = questionNode?.textContent;
    const answer = makeAnswerByNode(answerNode);
    if (!question || !answer) continue;
    mainEntity.push({
      '@type': 'Question',
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
      name: question,
    });
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  };
};

const makeAnswerByNode = (node: Element) => {
  const answer: string[] = [];
  for (const child of node?.children ?? []) {
    if (!child?.textContent) continue;
    if (child.tagName === 'P') answer.push(child?.textContent);
    if (child.tagName === 'UL') {
      const regex = new RegExp(/(\w+(?: \w+)*?)(?: a partire da \d+€| prezzo non disponibile)/, 'gi');
      const matches = Array.from(child.textContent?.matchAll(regex));
      const ul: string[] = [];
      for (const match of matches ?? []) {
        ul.push(`<br />- ${match[1].trim()} ${match[2].trim()}`);
      }
      answer.push(ul.join(''));
    }
  }
  return answer.join('<br />');
};

export const generateFaqListJsonLd = (faqs: Array<{ question: string; answer: string }>) => {
  const mainEntity = faqs.map(faq => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.answer,
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  };
};
