import { MedicalVisitCardStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import Link from 'next/link';
import { capitalizeWordsInString } from '@/utils/string';
import Image from 'next/image';
import { DAVINCI_CDN } from '@/costants/defaultValues';

export const StoryblokMedicalVisitCard: StoryblokReactComponent<MedicalVisitCardStoryblok> = ({ component, additionalData }) => {
  if (!component.link || !component.title) return null;

  return (
    <Link href={component.link.url.replace('[[city]]', additionalData.location ?? '')}>
      <li className="gap-15 flex w-full items-center justify-between">
        <div className="flex items-center gap-3">
          <Image src={`${DAVINCI_CDN}/inclinic/icons/medical.png`} width={15} height={15} alt="Medikit" />
          <span className="text-start">{component.title.replaceAll('[[city]]', capitalizeWordsInString(additionalData.location))}</span>
        </div>
        <Image src={`${DAVINCI_CDN}/inclinic/icons/chevron-right.png`} width={18} height={8} alt="freccia destra" />
      </li>
    </Link>
  );
};
